<template>
  <v-container fluid fill-height :class="$style.auth" class="d-flex justify-start align-center flex-column pa-0"
    style="width: 100%; min-width: 320px; max-width: 320px;">
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <migration-logo />
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-4">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center align-center" v-if="Object.keys(errorMessages).length">
          <v-card flat width="300" background="transparent" :style="{ backgroundColor: 'transparent' }">
            <error-list :errorMessages="errorMessages" :names="['message']" />
          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card color="#fff" class="py-1 pa-5 mb-3 rounded-xl" rounded :class="$style.form">
            <v-row dense>
              <v-col cols="12">
                <h3 style="font-weight: 600; color: red;" class="text-center py-1 pa-3 mb-3">
                  メールアドレス変更のお願い
                </h3>
              </v-col>
              <v-col cols="12">
                <h3 style="font-weight: 600;" class="text-center py-1 pa-3 mb-3">
                  メールアドレスに届いた認証コードを入力してください。
                </h3>
              </v-col>
              <v-col cols="12">
                <MyForm :formKey="formKey" :entity="entity" :input="input" ref="form">
                  <v-container class="pa-0">
                    <v-col cols="12">
                      <ValidationProvider v-slot="{ errors }" :rules="{
    required: 'required'
  }" name="認証コード" vid="confirmationCode" ref="v-confirmationCode">
                        <v-text-field v-model="input['confirmationCode']" name="confirmationCode"
                          label="認証コード Authentication Code" clearable autocomplete="off" :error-messages="errors[0]"
                          background-color="#fff">
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="entity.newMailAddress" name="mailAddress"
                        label="新しいメールアドレス New Email Address" clearable autocomplete="off" background-color="#fff"
                        disabled :class="$style.textfield">
                      </v-text-field>
                    </v-col>
                  </v-container>
                </MyForm>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <base-btn :disabled="btnDisabled" label="変更 Change" @click="onConfirm" :style="{
    color: 'white',
    fontSize: '1rem',
    boxShadow: 'none'
  }" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn rounded block large @click="onStop" :style="{
    'background-color': '#ffb95d', 'color': '#fff',
    fontSize: '1rem',
    boxShadow: 'none'
  }">メールアドレスを変更しない<br>※前ページに戻ります</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import MyForm from "@/entries/front/components/form";
import ErrorList from "@/entries/front/components/error/error-list";

export default {
  components: {
    MyForm,
    ErrorList
  },

  data() {
    return {
      resourceName: "migration",
      formKey: "migration-code-email",
      title: "アカウント移行手続き画面",
      titleEn: "Email Address Registration",
      input: {
        confirmationCode: ""
      },
      valid: {
        confirmationCode: false
      }
    };
  },
  watch: {
    "input.confirmationCode": {
      handler(value, old) {
        if (value !== old) {
          this.validate("confirmationCode");
        }
      }
    }
  },
  computed: {
    isError() {
      return this.$store.getters[`${this.resourceName}/entity/isError`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/entity/processing`];
    },
    entity() {
      return this.$store.getters[`${this.resourceName}/entity/entity`];
    },
    errorMessages() {
      return this.$store.getters[`${this.resourceName}/entity/errorMessages`];
    },
    btnDisabled() {
      const { confirmationCode } = this.valid;
      return !confirmationCode;
    }
  },
  methods: {
    async validate(refName) {
      const providor = this.$refs[`v-${refName}`];
      const { valid } = await providor.validate();
      this.$nextTick(() => {
        this.valid[refName] = valid;
      });
    },
    popstate() {
      window.history.pushState(null, null, null);
      return;
    },
    onConfirm() {
      this.$store
        .dispatch(`${this.resourceName}/entity/editMailAddress`, {
          confirmationCode: this.input["confirmationCode"]
        })
        .then(() => {
          this.$store.commit("form/clearDirty");
          this.$router.push({ name: "migration-start" });
        })
        .catch(() => { });
    },
    onStop() {
      this.$router.push({ name: "migration-start" });
    },
  },
  created() {
    // アカウント情報が存在しない場合は、アカウント情報画面に強制的に遷移
    if (!Object.keys(this.entity).length) {
      this.$router.push({ name: "migration-start" });
    }
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
    this.$store.dispatch(`${this.resourceName}/entity/clearError`);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.confirmationCode
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.reissue-link {
  font-size: 16px;
  text-decoration-line: none;
  color: $base-font-color !important;
}

.textfield {
  label {
    font-size: 14px !important;
  }
}
</style>
